@import '_mixins';
@import '_reset';

@include fontFace('Gotham', './../fonts/GothamPro');
@include fontFace('Gotham-Bold', './../fonts/GothamPro-Bold');
@include fontFace('Gotham-Black', './../fonts/GothamPro-Black');

$white-color:					#fff;
$black-color: 					#000;
$blue-color: 					#063b6f;
$orange-color: 					#e36513;
$gorange-color: 				#9d460d;

body {
	background:					url(./../img/bg.jpg);
	@include fontStyle (16px, Gotham, $white-color);
	@include backgroundStyle(center, cover, repeat); }

a {
	color: 						$white-color;
	text-decoration: 			none;

	&:hover {
		color: 					$white-color;
		text-decoration: 		none; } }

.bg-fix {
	position: 					fixed;
	width: 						100%;
	height: 					100%;
	left: 						0px;
	top: 						0px;
	z-index: 					-3;
	background:					url(./../img/bg.jpg);
	@include backgroundStyle(center, cover, repeat); }

.header {
	background: 				$white-color;
	padding: 					10px 0;

	span {
		display: 				block; }

	.logo {
		margin-top: 			8px;

		.media-body {
			padding-top: 		5px;

			span {

				&:nth-child(1) {
					@include fontStyle (20px, Gotham-Bold, $blue-color); }

				&:nth-child(2) {
					margin-top: -7px;
					@include fontStyle (12px, Gotham, $blue-color); } } } }

	.contacts {
		text-align: 			right;

		a {
			display: 			block;
			@include fontStyle (20px, Gotham-Bold, $blue-color); }

		span {
			margin-top: 		-5px;
			@include fontStyle (12px, Gotham-Bold, $blue-color); } } }

.title {
	padding-top: 				30px;
	text-align: 				center;
	@media screen and (max-width: 500px) {
		padding-top: 			20px; }

	h1 {
		@include fontStyle (50px, Gotham-Black, $white-color);
		@media screen and (max-width: 500px) {
			font-size: 			30px; } } }

.cta {

	.left-lift, .right-lift {
		margin-top: 			0px; }

	.right-lift {

		img {

			float: 				right; } }

	.cta-box {
		padding-top: 			20px;
		text-align: 			center;

		h2 {
			margin-top: 		20px;
			@include fontStyle (26px, Gotham-Black, $white-color);
			@media screen and (max-width: 500px) {
				font-size: 		16px; } }

		.button {
			position: 			relative;
			overflow: 			hidden;
			display: 			inline-block;
			margin: 			10px 0 20px 0;
			width: 				100%;
			max-width: 			400px;
			border: 			none;
			outline: 			none;
			text-align: 		center;
			padding: 			20px 0;
			background: 		linear-gradient(to bottom, $orange-color, $gorange-color);
			box-shadow: 		rgba(0, 0, 0, 0.3) 0px 9px 16px 0px;
			@include fontStyle (20px, Gotham-Black, $white-color);
			@include borderRadius(100px);
			@include transitionStyle(400ms);

			&:hover {
				box-shadow: 	none; }

			&:before {
				box-sizing: 	border-box;
				position: 		absolute;
				top: 			0px;
				left: 			0px;
				z-index: 		2;
				display: 		block;
				content: 		'';
				width: 			15%;
				height: 		100px;
				background: 	linear-gradient(to right,rgba(255,255,255,0) 0,rgba(255,255,255,.3) 100%);
				transform: 		skewX(-25deg);
				animation: 		shadow infinite 2s; }

			@keyframes shadow {
				from {
					left: 		-100px; }
				to {
					left: 		800px; } } } } }

.points {
	padding-top: 				40px;

	div {
		text-align: 			center;
		@media screen and (max-width: 500px) {
			margin-bottom: 		30px; }

		img {
			margin-bottom: 		20px; }

		.span-bold {
			margin-bottom: 		10px;
			@include fontStyle (18px, Gotham-Bold, $white-color); }

		span {
			display: 			block; } } }

.footer {
	margin-top: 				20px;
	background: 				$white-color;
	padding: 					10px 0;

	.logo {
		margin-top: 			5px;
		@include fontStyle (16px, Gotham, $blue-color); }

	.contacts {
		text-align: 			right;

		a {
			display: 			block;
			@include fontStyle (20px, Gotham-Bold, $blue-color); }

		span {
			display: 			block;
			margin-top: 		-5px;
			@include fontStyle (12px, Gotham-Bold, $blue-color); }

		.adress {
			@include fontStyle (12px, Gotham, $blue-color); } } }

.quiz-hack {
	position: 					fixed;
	width: 						100%;
	height: 					100%;
	top: 						0px;
	left: 						0px; }
